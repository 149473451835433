	/*!
 * jQuery Smart Banner
 * 'jQuery Ultimate SmartBanner' is provided under the Apache License, 2.0.
 */
!function($) {
	  var SmartBanner = function(config) {
	    // Get the original margin-top of the HTML element so we can take that into account.
        this.origHtmlMargin = parseFloat($('html').css('margin-top'));
        this.origOuterMargin = parseFloat($('.sab-wrapper.page').css('margin-top'));
		this.origPageHeader = parseFloat($('header.mobile-nav .top-nav').css('top'));

        this.config = $.extend({}, $.smartbanner.defaults, config);
        //Check if it's already a standalone web app or running within a webui view of an app (not mobile safari).
        var standalone = navigator.standalone;

        // Detect banner type (iOS or Android)
        if (this.config.force) {
            this.type = this.config.force;
        } else if ((navigator.userAgent.match(/iPad|iPhone|iPod/i) != null) || ((navigator.userAgent.match(/iPad/)) != null) ) {
            if (navigator.userAgent.match(/Safari/i) != null) {
                this.type = 'ios';
            }
        } else if (navigator.userAgent.match(/Android/i) != null) {
            this.type = 'android';
        }

        // Don't show banner if device isn't iOS or Android, website is loaded in app or user dismissed banner
        if ((this.type === 'ios') || ($.inArray(this.type, this.config.platforms) === -1) || standalone || this.getCookie('sb-closed') || this.getCookie('sb-installed')) {
            return;
        }

        // Calculate scale
        this.scale = this.config.scale == 'auto' ? $(window).width() / window.screen.width : this.config.scale;
        if (this.scale < 1) this.scale = 1

        // Get info from meta data
        var meta = $(this.type=='android' ? 'meta[name="google-play-app"]' : 'meta[name="apple-itunes-app"]');
        if (!meta.length) return;

        this.appId = /app-id=([^\s,]+)/.exec(meta.attr('content'))[1];
        this.title = this.config.title ? this.config.title : meta.data('title') || $('title').text().replace(/\s*[|\-·].*$/, '');
        this.author = this.config.author ? this.config.author : meta.data('author') || ($('meta[name="author"]').length ? $('meta[name="author"]').attr('content') : window.location.hostname);
        this.iconUrl = meta.data('icon-url');
        this.price = meta.data('price');

        // Create banner
        this.create();
        this.show();
        this.listen();
    }

    SmartBanner.prototype = {

        constructor: SmartBanner

      , create: function() {
            var iconURL
              , link=(this.config.url ? this.config.url : (this.type=='android' ? 'market://details?id=' : ('https://itunes.apple.com/' + this.config.appStoreLanguage + '/app/id')) + this.appId)
              , price = this.price || this.config.price
              , inStore = price ? price + ' - ' + (this.type=='android' ? this.config.inGooglePlay : this.config.inAppStore) : ''
              , gloss=this.config.iconGloss === null ? (this.type=='ios') : this.config.iconGloss;

              if (this.type !== 'ios') {
                $('.sab-wrapper.page').before('<div id="smartbanner" class="'+this.type+'"><div class="sb-container"><a href="#" class="sb-close">&times;</a><span class="sb-icon"></span><div class="sb-info"><strong>'+this.title+'</strong><span>'+this.author+'</span><span>'+inStore+'</span></div><a href="'+link+'" class="sb-button"><span>'+this.config.button+'</span></a></div></div>');
              } else {
                $('body').append('<div id="smartbanner" class="'+this.type+'"><div class="sb-container"><a href="#" class="sb-close">&times;</a><span class="sb-icon"></span><div class="sb-info"><strong>'+this.title+'</strong><span>'+this.author+'</span><span>'+inStore+'</span></div><a href="'+link+'" class="sb-button"><span>'+this.config.button+'</span></a></div></div>');
              }

            if (this.config.icon) {
                iconURL = this.config.icon;
            } else if(this.iconUrl) {
                iconURL = this.iconUrl;
            } else if ($('link[rel="apple-touch-icon-precomposed"]').length > 0) {
                iconURL = $('link[rel="apple-touch-icon-precomposed"]').attr('href');
                if (this.config.iconGloss === null) gloss = false;
            } else if ($('link[rel="apple-touch-icon"]').length > 0) {
                iconURL = $('link[rel="apple-touch-icon"]').attr('href');
            }
            if (iconURL) {
                $('#smartbanner .sb-icon').css('background-image','url('+iconURL+')');
                if (gloss) $('#smartbanner .sb-icon').addClass('gloss');
            } else{
                $('#smartbanner').addClass('no-icon');
            }

            this.bannerHeight = $('#smartbanner').outerHeight();   //+ 2
            if (this.type !== 'ios') {
              $('.sab-wrapper.page').css('margin-top',this.bannerHeight);
              $('header.mobile-nav .top-nav').css('top',this.bannerHeight);
              $('#smartbanner').css('position','fixed');
            }
            if (this.scale > 1) {
                $('#smartbanner')
                    .css('top', parseFloat($('#smartbanner').css('top')) * this.scale)
                    .css('height', parseFloat($('#smartbanner').css('height')) * this.scale);
                $('#smartbanner .sb-container')
                    .css('-webkit-transform', 'scale('+this.scale+')')
                    .css('-msie-transform', 'scale('+this.scale+')')
                    .css('-moz-transform', 'scale('+this.scale+')')
                    .css('width', $(window).width() / this.scale);
            }
        }

      , listen: function () {
            $('#smartbanner .sb-close').on('click',$.proxy(this.close, this));
            $('#smartbanner .sb-button').on('click',$.proxy(this.install, this));
        }

      , show: function(callback) {
            $('#smartbanner').stop().animate({top:0},this.config.speedIn).addClass('shown');
            if (this.type !== 'ios') {
              $('html').animate({marginTop:this.origHtmlMargin},this.config.speedIn,'swing',callback);
            } else {
              $('html').animate({marginTop:this.origHtmlMargin+(this.bannerHeight*this.scale)},this.config.speedIn,'swing',callback);
            }
        }

      , hide: function(callback) {
            $('#smartbanner').stop().animate({top:-1*this.bannerHeight*this.scale},this.config.speedOut).removeClass('shown');
            $('html').animate({marginTop:this.origHtmlMargin},this.config.speedOut,'swing',callback);
            if (this.type !== 'ios') {
              $('.sab-wrapper.page').css('margin-top',this.origOuterMargin);
              $('header.mobile-nav .top-nav').css('top',_.isUndefined(this.origPageHeader) ? 0 : this.origPageHeader);
              $('#smartbanner').css('position','absolute');
            }
        }

      , getScale: function(e){
          return this.scale;
        }

      , close: function(e) {
            e.preventDefault();
            this.hide();
            this.setCookie('sb-closed','true',this.config.daysHidden);
            $('#smartbanner').remove();
		    document.body.dispatchEvent(
		 	  new CustomEvent('sb-closed',{})
		    );
            return false;
        }

      , install: function(e) {
            this.hide();
            this.setCookie('sb-installed','true',this.config.daysReminder);
        }

      , setCookie: function(name, value, exdays) {
            var exdate = new Date();
            exdate.setDate(exdate.getDate()+exdays);
            value=escape(value)+((exdays==null)?'':'; expires='+exdate.toUTCString());
            document.cookie=name+'='+value+'; path=/; secure';
        }

      , getCookie: function(name) {
            var i,x,y,ARRcookies = document.cookie.split(";");
            for(i=0;i<ARRcookies.length;i++) {
                x = ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
                y = ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
                x = x.replace(/^\s+|\s+$/g,"");
                if (x==name) {
                    return unescape(y);
                }
            }
            return null;
        }

      // Demo only
      , switchType: function() {
          var that = this;

          this.hide(function() {
            that.type = that.type=='android' ? 'ios' : 'android';
            var meta = $(that.type=='android' ? 'meta[name="google-play-app"]' : 'meta[name="apple-itunes-app"]').attr('content');
            that.appId = /app-id=([^\s,]+)/.exec(meta)[1];

            $('#smartbanner').detach();
            that.create();
            that.show();
          })
        }
    }

    $.smartbanner = function(option) {
        var $window = $(window)
        , data = $window.data('typeahead')
        , config = typeof option == 'object' && option;
      if (!data) $window.data('typeahead', (data = new SmartBanner(config)));
      if (typeof option == 'string') data[option]();
    };

    // override these globally if you like (they are all optional)
    $.smartbanner.defaults = {
        title: null, // What the title of the app should be in the banner
						// (defaults to <title>)
        author: null, // What the author of the app should be in the banner
						// (defaults to <meta name="author"> or hostname)
        price: 'Free', // Price of the app
        appStoreLanguage: 'us', // Language code for App Store
        inAppStore: 'On the App Store', // Text of price for iOS
        inGooglePlay: 'On Google Play', // Text of price for Android
        icon: null, // The URL of the icon (defaults to <meta
					// name="apple-touch-icon">)
        iconGloss: null, // Force gloss effect for iOS even for precomposed
        button: 'View', // Text for the install button
        url: null, // The URL for the button. Keep null if you want the button
					// to link to the app store.
        scale: 'auto', // Scale based on viewport size (set to 1 to disable)
        speedIn: 300, // Show animation speed of the banner
        speedOut: 400, // Close animation speed of the banner
        daysHidden: 15, // Duration to hide the banner after being closed (0 =
						// always show banner)
        daysReminder: 90, // Duration to hide the banner after "VIEW" is
							// clicked *separate from when the close button is
							// clicked* (0 = always show banner)
        force: null, // Choose 'ios' or 'android'. Don't do a browser check, just
					// always show this banner
        platforms: ['ios', 'android'] // The platforms where the Smart banner will be displayed
    }

    $.smartbanner.Constructor = SmartBanner

}(window.jQuery);

if (window.innerWidth < 1200) {
  var android = location.href.match(/#android$/) || navigator.userAgent.match(/Android/i) != null;
  $.smartbanner({
    title: 'Wyndham Rewards',
    author: 'Wyndham Hotel Group',
    icon: 'http://lh3.googleusercontent.com/NCPLucFb18xit0y04Fnn_3JtLt3-glKcTx_JLpXioVwSQzDzuj7jezgNz7Qna3JSVSY=w300-rw',
    force: android ? 'android' : 'ios',
    daysHidden: 7,
    daysReminder: 0
  });

  $('.theme-toggle').on('click', function(e) {
    e.preventDefault();
    $.smartbanner('switchType');
    $(this).text($(this).text() == 'Preview Android' ? 'Preview iOS' : 'Preview Android');
  })
  if (android) $('.theme-toggle').text('Preview iOS');

  $( 'nav input.destination' ).on('focus', function() {
	var sab = $('#smartbanner');
	if (sab.length > 0) {
	  sab.hide();
	  $('.sab-wrapper.page').css('margin-top',0);
	  $('header.mobile-nav .top-nav').css('top',0);
	}
  })
  $( 'nav input.destination' ).on('blur', function() {
    var sab = $('#smartbanner');
    if (sab.length > 0) {
      sab.show();
      $('.sab-wrapper.page').css('margin-top', $('#smartbanner').outerHeight());
      $('header.mobile-nav .top-nav').css('top', $('#smartbanner').outerHeight());
    }
  })
}
